.ContentBlock-module__contentBlock___3OFeg {
  color: #333;
  text-align: left;
  padding: 5px 20px;
}

.ContentBlock-module__contentBlockHeading___1Z35z>h2 {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex: 1 1;
      flex: 1 1;
}

.ContentBlock-module__contentBlockBody___3dDfZ {
  overflow: hidden;
}

/* Default style */

.ContentBlock-module__contentBlockStyle_default___iZ9jc {
  background: #ffffff;
}

.ContentBlock-module__contentBlockStyle_default___iZ9jc>.ContentBlock-module__contentBlockHeading___1Z35z {
  border-bottom: 1px solid #ebebeb;
}

/* Transparent style */

.ContentBlock-module__contentBlockStyle_transparent___2IjCC {
  background: transparent;
  border: 1px solid #a8c0ef;
  border-radius: 8px;
  margin: 10px;
}

.ContentBlock-module__contentBlockStyle_purple___3e-qs {
  background: transparent;
  border: 1px solid #c8c4fb;
  border-radius: 8px;
}

.ContentBlock-module__contentBlockStyle_defaultPurple___3ewMW {
  background: #ffffff;
  border: 1px solid #c8c4fb;
}

.ContentBlock-module__contentBlockStyle_transparent___2IjCC>.ContentBlock-module__contentBlockHeading___1Z35z {
  border-bottom: 1px solid #a8c0ef;
}

.ContentBlock-module__contentBlockStyle_purple___3e-qs>.ContentBlock-module__contentBlockHeading___1Z35z {
  border-bottom: 1px solid #c8c4fb;
}

.ContentBlock-module__contentBlockStyle_defaultPurple___3ewMW>.ContentBlock-module__contentBlockHeading___1Z35z {
  border-bottom: 1px solid #c8c4fb;
}

/* Content block payments style */
.ContentBlock-module__paymentMethods___5BOAC {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  padding: 10px 15px !important;
}

.ContentBlock-module__headingWrapper___wYb7g {
  background-color: #f8f8f8;
  padding: 10px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: center;
      align-items: center;
}

.ContentBlock-module__heading___2bp3N {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.ContentBlock-module__badgeWrapper___15fQ3 {
  -ms-flex: 1 1;
      flex: 1 1;
}

.ContentBlock-module__headingWrapper___wYb7g h2 {
  font-size: 14px;
  line-height: 1em;
  margin-bottom: 0;
  display: inline;
  margin-left: 15px;
}

.ContentBlock-module__headingWrapper___wYb7g h2 i{
  margin-left: -18px;
}

.ContentBlock-module__creditButton___fgGY- {
  max-width: 100px;
}

.ContentBlock-module__checkBox___mSv0y {
  margin-top: 10px;
}

.ContentBlock-module__checkBox___mSv0y label {
  margin-left: 20px;
  font-size: 13px;
}

.ContentBlock-module__checkBox___mSv0y input {
  margin-left: -20px;
}

.ContentBlock-module__contentBlockHeading___1Z35z {
  color: #41495f;
  margin: 0 0 20px;
  padding: 10px 0;
}

.ContentBlock-module__headingContent___1Y4XN {
  background: #f8f8f8;
  padding: 0px 15px 3px 15px;
  text-align: right;
}

.ContentBlock-module__addBank___3daSB {
  color: #ffffff !important;
}

/* Media queries */

@media only screen and (min-width: 375px) {
  .ContentBlock-module__creditButton___fgGY- {
    max-width: 100%;
  }
}

@media only screen and (min-width: 767px) {
  .ContentBlock-module__contentBlock___3OFeg {
    border-radius: 8px;
  }

  .ContentBlock-module__contentBlockHeading___1Z35z {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ContentBlock-module__contentBlockStyle_default___iZ9jc {
    border: 1px solid #ebebeb;
  }

  .ContentBlock-module__contentBlockStyle_transparent___2IjCC {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}